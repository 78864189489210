import React from "react";
import { useSelector } from "react-redux";
import { Tooltip, PieChart, Pie } from "recharts";
import {
  getTotalConsultantCost,
  getTotalEducationCost,
  getTotalUsedEquipmentCost,
  getTotalFixedCost,
  getTotalPersonnelAndPartnerCost,
  getTotalPrintedCost,
  getTotalCalibrationAndCheckCost,
} from "store/slices/cost/costSlice";

import useQualityTeamData from "./useQualityTeamData";

function QualityTeamChart({ overview }) {
  const total_personnel_and_partner_cost = useSelector(
    getTotalPersonnelAndPartnerCost
  );
  const total_fixed_cost = useSelector(getTotalFixedCost);
  const total_used_equipment = useSelector(getTotalUsedEquipmentCost);
  const total_education_cost = useSelector(getTotalEducationCost);
  const printed_cost = useSelector(getTotalPrintedCost);
  const callibration_and_check_cost = useSelector(
    getTotalCalibrationAndCheckCost
  );
  const total_consultant_cost = useSelector(getTotalConsultantCost);

  const graphData = useQualityTeamData({
    total_personnel_and_partner_cost,
    total_fixed_cost,
    total_used_equipment,
    total_education_cost,
    printed_cost,
    callibration_and_check_cost,
    total_consultant_cost,
  });

  return (
    <div id="qualityTeamChart" className="bg-blue-900 rounded ">
      <div className="text-center text-lg my-2 text-white">Quality team</div>
      <PieChart width={500} height={200} margin={{ left: 150 }}>
        <Pie
          dataKey="cost"
          isAnimationActive={false}
          data={graphData}
          cx={100}
          cy={100}
          outerRadius={60}
          label
        />

        <Tooltip />
      </PieChart>
    </div>
  );
}

export default QualityTeamChart;
