import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setCallibrationAndCheckCost,
  selectCallibrationAndCheckCost,
} from "store/slices/cost/costSlice";

const CallibrationAndCheckCosttSchema = Yup.object().shape({
  callibration: Yup.number().integer().moreThan(0).required("Required"),
});

function CalibrationAndCheckCostValue({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const callibration_and_check_cost = useSelector(
    selectCallibrationAndCheckCost
  );

  const { callibration } = callibration_and_check_cost;

  useEffect(() => {
    const total = callibration;

    setStepTotal(total);
  }, [callibration]);

  return (
    <div>
      <div className="text-center text-lg my-2">
        Callibration and check cost
      </div>
      <Formik
        initialValues={{
          callibration,
        }}
        validationSchema={CallibrationAndCheckCosttSchema}
        onSubmit={async (values) => {
          dispatch(setCallibrationAndCheckCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;

          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="callibration"
                  className={sharedClasses.labelClasses}
                >
                  How much the callibration and checks costs (€)?
                </label>
                <Field
                  type="number"
                  id="callibration"
                  name="callibration"
                  className={sharedClasses.inputClasses}
                  placeholder="How much the callibration and checks costs ?"
                />
                <ErrorMessage
                  name="callibration"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total callibration and checks cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/printed-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/consultant-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CalibrationAndCheckCostValue;
