import { createSlice } from "@reduxjs/toolkit";
import { logout } from "store/slices/auth/authSlice";

const initialState = {
  alert: {},
};

export const effectsSlice = createSlice({
  name: "effects",
  initialState,
  reducers: {
    getEffectsFailure: (state, action) => {
      const { alert } = action.payload;
      state.alert = alert;
    },
    resetEffectsAlert: (state, action) => {
      state.alert = {};
    },
    setEffectsAlert: (state, action) => {
      const { alert } = action.payload;

      state.alert = alert;
    },
  },
  // https://redux-toolkit.js.org/api/createslice/#customizing-generated-action-creators
  extraReducers: (builder) => {
    // empty state on logout
    builder
      .addCase(logout({}).type, (_state, _action) => {
        return { ...initialState };
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, _action) => state);
  },
});

// Action creators are generated for each case reducer function
export const { getEffectsFailure, resetEffectsAlert, setEffectsAlert } =
  effectsSlice.actions;

export const selectEffectsAlert = ({ effects }) => {
  return effects?.alert;
};

export default effectsSlice.reducer;
