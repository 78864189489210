import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function CostBars({ graphData }) {
  return (
    <div id="barChart" className="bg-blue-900 rounded ">
      <div className="text-center text-lg my-2 text-white">Cost analysis</div>
      <BarChart
        width={500}
        height={200}
        data={graphData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" tick={{ fill: "white" }} />
        <YAxis tick={{ fill: "white" }} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="cost" fill="#ffffff" />
      </BarChart>
    </div>
  );
}

export default CostBars;
