import axios from "axios";
import headers from "shared/headers";
import * as config from "shared/config";

const createEffect = async ({ jwt, ...values }) => {
  try {
    const url = `${config.BASE_PATH}/createEffect`;
    const res = await axios.post(url, values, headers({ jwt }));
    const { data } = res;
    return data;
  } catch (e) {
    const {
      data: { message },
      status,
    } = e.response;

    console.log("Create effect error:", e.response);
    const error = {
      status,
      message,
    };

    throw error;
  }
};

export default createEffect;
