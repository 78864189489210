import axios from "axios";
import headers from "shared/headers";
import * as config from "shared/config";
const deleteCauses = async ({ jwt, ids: possible_cause_ids }) => {
  try {
    const url = `${config.BASE_PATH}/deleteCauses`;

    const res = await axios.delete(url, {
      ...headers({ jwt }),
      data: { possible_cause_ids },
    });

    const { data } = res;
    return data;
  } catch (e) {
    const {
      data: { message },
      status,
    } = e.response;

    console.log("Delete causes error:", e.response);
    const error = {
      status,
      message,
    };

    throw error;
  }
};

export default deleteCauses;
