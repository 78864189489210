import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { classnames } from "tailwindcss-classnames";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import isError from "lodash/isError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import useToggle from "hooks/useToggle";
import * as authApi from "store/slices/auth/api";
import { loginSuccess, loginFailure } from "store/slices/auth/authSlice";
import * as queryTypes from "shared/queryTypes";
import { useDispatch } from "react-redux";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

function LoginForm(props) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useToggle();
  const navigate = useNavigate();
  const { mutations } = queryTypes;
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(
    ({ username, password }) => {
      return authApi.login({ username, password });
    },
    {
      mutationKey: mutations.login,
      onSuccess: (res) => {
        const gotError = isError(res);
        if (!gotError) {
          const { jwt } = res;
          console.log("jwt", jwt);

          dispatch(loginSuccess({ jwt }));
          navigate(state?.path || "/dashboard");
        }
      },
      onError: (error) => {
        console.log("got error", error);
        dispatch(loginFailure({ alert: error }));
      },
    }
  );

  const togglePassword = () => setShowPassword(!showPassword);

  const inputClasses = classnames(
    " border border-gray-400 rounded-sm p-1 focus:outline-none"
  );

  const btnClasses = classnames(
    inputClasses,
    "bg-green-400 text-white hover:bg-green-600 focus:outline-none my-4"
  );

  const labelClasses = classnames("my-2");
  const errorClasses = classnames("text-red-500");

  const eyClasses = classnames(
    "absolute top-2 right-2 cursor-pointer  text-green-400"
  );

  return (
    <div
      className={classnames(
        "flex flex-col justify-center items-center h-screen z-10"
      )}
    >
      <div className={classnames("text-gray-50", "m-2", "text-xl")}>
        Welcome
      </div>

      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          console.log(JSON.stringify(values, null, 2));
          mutate({ ...values });
        }}
      >
        <Form className="bg-gray-50 p-5 rounded-sm ">
          <div className={classnames("flex flex-col")}>
            <label htmlFor="username" className={labelClasses}>
              User Name
            </label>
            <Field
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              className={inputClasses}
            />
            <ErrorMessage
              name="username"
              component="div"
              className={errorClasses}
            />

            <label htmlFor="password" className={labelClasses}>
              Password
            </label>

            <Field id="password" name="password">
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => {
                return (
                  <div className={classnames("relative")}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className={classnames(inputClasses)}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                    <FontAwesomeIcon
                      icon={faEye}
                      className={eyClasses}
                      onClick={togglePassword}
                    />
                  </div>
                );
              }}
            </Field>
            <ErrorMessage
              name="password"
              component="div"
              className={errorClasses}
            />
            <button type="submit" className={btnClasses}>
              {isLoading ? "Wait" : "Log in"}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default LoginForm;
