import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const ExpandSidebar = ({ menuIconClick, menuCollapse }) => {
  return (
    <div
      className="toggleSidebar"
      onClick={menuIconClick}
      key={menuCollapse ? "collapsed" : "expanded"}
    >
      <FontAwesomeIcon
        icon={menuCollapse ? faArrowCircleRight : faArrowCircleLeft}
        className="text-green-900"
      />
    </div>
  );
};

export default ExpandSidebar;
