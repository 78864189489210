import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import { useSelector } from "react-redux";
import { isNaN } from "lodash";
import { selectOverview } from "store/slices/cost/costSlice";

function Overview({ next, previous }) {
  const navigate = useNavigate();

  const {
    totalQualityTeamCost,
    totalProductionCost,
    totalProductionPercentagePerBottle,
    totalQualityProductionCost,
  } = useSelector(selectOverview);

  return (
    <div>
      <div className="text-center text-xl my-2">Overview</div>

      <div className="text-lg">
        Total quality team cost (€):{" "}
        {isNaN(totalQualityTeamCost) ? (
          <span className="text-red-800"> Please fill the previous steps</span>
        ) : (
          totalQualityTeamCost
        )}
      </div>
      <div className="text-lg">
        Total production cost (€):
        {isNaN(totalProductionCost) ? (
          <span className="text-red-800"> Please fill the previous steps</span>
        ) : (
          totalProductionCost
        )}
      </div>
      <div className="text-lg">
        Total production cost per bottle (€):
        {isNaN(totalProductionPercentagePerBottle) ? (
          <span className="text-red-800"> Please fill the previous steps</span>
        ) : (
          totalProductionPercentagePerBottle.toFixed(2)
        )}
      </div>
      <div className="text-lg">
        Total quality production cost (€):
        {isNaN(totalQualityProductionCost) ? (
          <span className="text-red-800"> Please fill the previous steps</span>
        ) : (
          totalQualityProductionCost.toFixed(2)
        )}
      </div>
      <Button
        type="button"
        color="secondary"
        className={sharedClasses.btnClasses}
        onClick={() => {
          previous();
          navigate("/cost_calculation/consultant-cost");
        }}
      >
        Back
      </Button>
    </div>
  );
}

export default Overview;
