import axios from "axios";
import headers from "shared/headers";
import * as config from "shared/config";

const getSystemParts = async ({ jwt }) => {
  try {
    const url = `${config.BASE_PATH}/getSystemParts`;

    const res = await axios.get(url, headers({ jwt }));

    const { data } = res;
    return data;
  } catch (e) {
    const {
      data: { message },
      status,
    } = e.response;

    console.log("Get system parts Error:", e.response);
    const error = {
      status,
      message,
    };

    throw error;
  }
};

export default getSystemParts;
