import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Steps, Step } from "react-albus";
import ProgressBar from "progress_bar_for_react";
import { useNavigate } from "react-router-dom";
import * as FormSteps from "./steps";

function StepWizard({ step, steps, previous, next, history, push, ...rest }) {
  const navigate = useNavigate();
  const params = useParams();

  const isLastStep = steps.indexOf(step) + 1 === steps.length;

  const currentStep = params.step;

  useEffect(() => {
    //! get current step and push that to reload the current step on browser refresh
    push(currentStep);
  }, [currentStep, push]);

  const stepPercentage = ((steps.indexOf(step) + 1) / steps.length) * 100;
  return (
    <div className="w-full">
      <ProgressBar
        percentage={stepPercentage}
        fillerExtraStyles={{
          backgroundColor: "red",
        }}
        progressBarExtraStyles={{
          position: "relative",
          height: "5px",
          width: "100%",
          borderRadius: "1px",
          border: "1px solid #d9d9d9",
          backgroundColor: "#d9d9d9",
        }}
      />
      <Steps key={step.id} step={step.id ? step : undefined}>
        <Step id="bottling-cost">
          <FormSteps.BottlingCost next={next} previous={previous} push={push} />
        </Step>
        <Step id="personnel-cost">
          <FormSteps.PersonnelPartnerCost next={next} previous={previous} />
        </Step>
        <Step id="fixed-assets-cost">
          <FormSteps.FixedAssetsCosts next={next} previous={previous} />
        </Step>
        <Step id="used-equipment-cost">
          <FormSteps.UsedEquipmentCost next={next} previous={previous} />
        </Step>
        <Step id="consumable-cost">
          <FormSteps.ConsumableCost next={next} previous={previous} />
        </Step>
        <Step id="education-cost">
          <FormSteps.EducationCost next={next} previous={previous} />
        </Step>
        <Step id="printed-cost">
          <FormSteps.PrintedCostValue next={next} previous={previous} />
        </Step>
        <Step id="callibration-check-cost">
          <FormSteps.CalibrationAndCheckCostValue
            next={next}
            previous={previous}
          />
        </Step>
        <Step id="consultant-cost">
          <FormSteps.ConsultantCost next={next} previous={previous} />
        </Step>
        <Step id="overview">
          <FormSteps.Overview next={next} previous={previous} />
        </Step>
      </Steps>
      <span className="mt-2 flex flex-row items-center justify-between">
        <span className="text-gray-400">
          Step {steps.indexOf(step) + 1}/{steps.length}
        </span>

        <span>
          <span
            className="text-gray-400 text-xs cursor-pointer hover:text-blue-400 mr-2"
            onClick={() => {
              const url = isLastStep
                ? "/cost_charts"
                : "/cost_calculation/overview";
              navigate(url);
              window.location.reload();
            }}
          >
            {isLastStep ? "Go to charts" : "Go to last step"}
          </span>
          {isLastStep && (
            <span
              className="text-gray-400 text-xs cursor-pointer hover:text-blue-400 mr-2"
              onClick={() => {
                const url = "/cost_calculation/bottling_cost";
                navigate(url);
                window.location.reload();
              }}
            >
              Go to first step
            </span>
          )}
        </span>
      </span>
    </div>
  );
}

export default StepWizard;
