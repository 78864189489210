//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import { useLocation } from "react-router-dom";

import {
  faHome,
  faBoxes,
  faSignOutAlt,
  faExclamation,
  faMagic,
  faLightbulb,
  faCheckCircle,
  faFile,
  faFileAlt,
  faProjectDiagram,
  faDollarSign,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classnames } from "tailwindcss-classnames";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "components/Footer/Footer";

import { logout } from "store/slices/auth/authSlice";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import ExpandSidebar from "./ExpandSidebar";
import "./Sidebar.css";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = (e) => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const sidebarClasses = classnames({
    header_collapsed: menuCollapse,
    header_expanded: !menuCollapse,
  });

  const handleClick = (e) => {
    const textLink = e.target.innerText.toLowerCase();
    navigate(`/${textLink}`);
  };
  const gotToHome = (e) => {
    navigate(`/dashboard}`);
  };

  const handleLogout = (e) => {
    dispatch(logout());
    navigate("/");
  };

  const handleCostCalculation = (e) => {
    navigate("/cost_calculation/bottling_cost");
  };

  const handleCostCharts = (e) => {
    navigate("/cost_charts");
  };

  return (
    <>
      <div id="sidebar" className={sidebarClasses}>
        <ExpandSidebar
          menuIconClick={menuIconClick}
          menuCollapse={menuCollapse}
        />
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {/* small and big change using menucollapse state */}
              {/* <p>{menuCollapse ? "Logo" : "Big Logo"}</p> */}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem
                active={pathname === "/dashboard"}
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={gotToHome}
              >
                Home
              </MenuItem>

              <MenuItem
                active={pathname === "/parts"}
                icon={<FontAwesomeIcon icon={faBoxes} />}
                onClick={handleClick}
              >
                Parts
              </MenuItem>

              <MenuItem
                active={pathname === "/failures"}
                icon={<FontAwesomeIcon icon={faExclamation} />}
                onClick={handleClick}
              >
                Failures
              </MenuItem>

              <MenuItem
                active={pathname === "/effects"}
                icon={<FontAwesomeIcon icon={faMagic} />}
                onClick={handleClick}
              >
                Effects
              </MenuItem>
              <MenuItem
                active={pathname === "/causes"}
                icon={<FontAwesomeIcon icon={faLightbulb} />}
                onClick={handleClick}
              >
                Causes
              </MenuItem>
              <MenuItem
                active={pathname === "/checks"}
                icon={<FontAwesomeIcon icon={faCheckCircle} />}
                onClick={handleClick}
              >
                Checks
              </MenuItem>

              <MenuItem
                active={pathname === "/fmea"}
                icon={<FontAwesomeIcon icon={faFile} />}
                onClick={handleClick}
              >
                FMEA
              </MenuItem>

              <MenuItem
                active={pathname === "/fishbone"}
                icon={<FontAwesomeIcon icon={faProjectDiagram} />}
                onClick={handleClick}
              >
                Fishbone
              </MenuItem>
              <SubMenu
                title="Cost"
                icon={
                  <FontAwesomeIcon
                    icon={faFile}
                    onClick={() => {
                      debugger;
                    }}
                  />
                }
              >
                <MenuItem
                  active={pathname === "/cost_calculation"}
                  icon={<FontAwesomeIcon icon={faDollarSign} />}
                  onClick={handleCostCalculation}
                >
                  Calculation
                </MenuItem>
                <MenuItem
                  active={pathname === "/cost_charts"}
                  icon={<FontAwesomeIcon icon={faChartPie} />}
                  onClick={handleCostCharts}
                >
                  Cost Charts
                </MenuItem>
              </SubMenu>
              <MenuItem
                active={pathname === "/severity"}
                icon={<FontAwesomeIcon icon={faFileAlt} />}
                onClick={handleClick}
              >
                Severity
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                onClick={handleLogout}
              >
                Logout
              </MenuItem>
              <Footer />
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
