import React from "react";
import LoginForm from "./LoginForm";
import { classnames } from "tailwindcss-classnames";
import Particles from "react-tsparticles";
import { particlesJson } from "./particlesJson";
import Footer from "components/Footer/Footer";

const Login = () => {
  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  const particlesInit = (main) => {
    console.log(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div
      className={classnames(
        "flex flex-col justify-center items-center h-screen"
      )}
    >
      <LoginForm />
      <Particles
        id="tsparticles"
        options={particlesJson}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <Footer />
    </div>
  );
};

export default Login;
