import { useState } from "react";

/**
 * rowKey usually is the id of the row
 **/
const useSelectedRowsHook = ({ rowKey }) => {
  const [selectedRows, setSelectedRows] = useState({});
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect && row[rowKey]) {
        setSelectedRows({
          ...selectedRows,
          [`${row[rowKey]}`]: { ...row },
        });
      }

      if (!isSelect) {
        const newSelectedRows = {};
        //drop key
        Object.keys(selectedRows)
          .filter((key) => {
            return `${key}` !== `${row[rowKey]}`;
          })
          .forEach((key) => {
            newSelectedRows[key] = selectedRows[key];
          });

        setSelectedRows(newSelectedRows);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      let newSelectedRows = {};

      if (isSelect) {
        rows.forEach((row) => {
          newSelectedRows[`${row[rowKey]}`] = row;
        });
      }
      setSelectedRows({
        ...selectedRows,
        ...newSelectedRows,
      });

      if (!isSelect) {
        const ids = rows.map((row) => `${row[rowKey]}`);
        const keys = Object.keys(selectedRows);

        keys.forEach((key) => {
          if (!ids.includes(key)) {
            //keeping id
            newSelectedRows[key] = selectedRows[key];
          }
        });

        setSelectedRows({ ...newSelectedRows });
      }
    },
  };

  return [selectedRows, selectRow, setSelectedRows];
};

export default useSelectedRowsHook;
