import { createSlice } from "@reduxjs/toolkit";
import { logout } from "store/slices/auth/authSlice";

const initialState = {
  alert: {},
};

export const partsSlice = createSlice({
  name: "parts",
  initialState,
  reducers: {
    getPartsFailure: (state, action) => {
      const { alert } = action.payload;
      state.alert = alert;
    },
    resetPartsAlert: (state, action) => {
      state.alert = {};
    },
    setPartsAlert: (state, action) => {
      const { alert } = action.payload;

      state.alert = alert;
    },
  },
  // https://redux-toolkit.js.org/api/createslice/#customizing-generated-action-creators
  extraReducers: (builder) => {
    // empty state on logout
    builder
      .addCase(logout({}).type, (_state, _action) => {
        return { ...initialState };
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, _action) => state);
  },
});

// Action creators are generated for each case reducer function
export const { getPartsFailure, resetPartsAlert, setPartsAlert } =
  partsSlice.actions;

export const selectPartsAlert = ({ parts }) => {
  return parts?.alert;
};

export default partsSlice.reducer;
