import React from "react";

function DeleteModalContent({ ids, attribute }) {
  return (
    <div>
      Are you sure you want to delete {attribute}(s) {ids}?
    </div>
  );
}

export default DeleteModalContent;
