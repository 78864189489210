import { createSlice } from "@reduxjs/toolkit";
import { logout } from "store/slices/auth/authSlice";

const initialState = {
  alert: {},
};

export const fishboneSlice = createSlice({
  name: "fishbone",
  initialState,
  reducers: {
    getFishboneFailure: (state, action) => {
      const { alert } = action.payload;
      state.alert = alert;
    },
    resetFishboneAlert: (state, action) => {
      state.alert = {};
    },
    setFishboneAlert: (state, action) => {
      const { alert } = action.payload;

      state.alert = alert;
    },
  },
  // https://redux-toolkit.js.org/api/createslice/#customizing-generated-action-creators
  extraReducers: (builder) => {
    // empty state on logout
    builder
      .addCase(logout({}).type, (_state, _action) => {
        return { ...initialState };
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, _action) => state);
  },
});

// Action creators are generated for each case reducer function
export const { getFishboneFailure, resetFishboneAlert, setFishboneAlert } =
  fishboneSlice.actions;

export const selectFishboneAlert = ({ fishbone }) => {
  return fishbone?.alert;
};

export default fishboneSlice.reducer;
