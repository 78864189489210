export const columns = [
  {
    dataField: "id",
    text: "Id",
    style: () => {
      return { width: "5%", textAlign: "center" };
    },
    headerStyle: () => {
      return {
        width: "5%",
        textAlign: "center",
      };
    },
  },
  {
    dataField: "procedure_step",
    text: "Procedure step",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "variable",
    text: "Variable",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "failure_description",
    text: "Failure",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "effect_description",
    text: "Effect",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "effects_score",
    text: "Effects score",
    style: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
  },
  {
    dataField: "cause_description",
    text: "Cause",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "causes_score",
    text: "Causes score",
    style: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
  },
  {
    dataField: "check_description",
    text: "Check",
    style: () => {
      return { overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { overflowWrap: "break-word" };
    },
  },
  {
    dataField: "check_score",
    text: "Check score",
    style: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
  },
  {
    dataField: "RPN",
    text: "RPN",
    style: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
    headerStyle: () => {
      return { width: "5%", overflowWrap: "break-word" };
    },
  },
];
