import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const Dialog = ({
  className,
  modalContent,
  modalTitle,
  modalFooter,
  toggle,
  modal,
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>{modalContent}</ModalBody>
      <ModalFooter>{modalFooter}</ModalFooter>
    </Modal>
  );
};

export default Dialog;
