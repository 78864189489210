import React from "react";

function Footer(props) {
  return (
    <div className="text-gray-50 z-10 text-xs">
      @2021 Studying quality 1.0.0 - All rights reserved.
    </div>
  );
}

export default Footer;
