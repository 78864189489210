import axios from "axios";
import headers from "shared/headers";
import * as config from "shared/config";
const deleteEffects = async ({ jwt, ids: possible_effect_ids }) => {
  try {
    const url = `${config.BASE_PATH}/deleteEffects`;

    const res = await axios.delete(url, {
      ...headers({ jwt }),
      data: { possible_effect_ids },
    });

    const { data } = res;
    return data;
  } catch (e) {
    const {
      data: { message },
      status,
    } = e.response;

    console.log("Delete part error:", e.response);
    const error = {
      status,
      message,
    };

    throw error;
  }
};

export default deleteEffects;
