import React from "react";
import * as queryTypes from "shared/queryTypes";
import { selectJwtToken } from "store/slices/auth/authSlice";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import * as api from "store/slices/fmea/api";

import { getFmeaFailure } from "store/slices/fmea/fmeaSlice";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import PageLabel from "shared/PageLabel";
import PageContainer from "shared/PageContainer";
import TableControls from "shared/TableControls";
import Spinner from "shared/spinner/Spinner";

import "./FMEA.css";

import { columns } from "./columns";

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

function FMEA(props) {
  const { queries } = queryTypes;

  const dispatch = useDispatch();
  const jwt = useSelector(selectJwtToken);

  const { data } = useQuery(
    queries.getFmeaScores,
    () => api.getFmeaScores({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getFmeaFailure({ error }));
      },
    }
  );

  if (!data) {
    return (
      <div className="self-center">
        <Spinner />
      </div>
    );
  }

  return (
    <PageContainer>
      <PageLabel label="FMEA" />
      <ToolkitProvider
        keyField="id"
        data={data || []}
        columns={columns}
        search
        exportCSV
      >
        {({ searchProps, baseProps, csvProps }) => (
          <>
            <TableControls>
              <div className="mr-2 text-white">
                <ExportCSVButton
                  {...csvProps}
                  className="btn btn-success outline focus:outline-none"
                >
                  Export CSV
                </ExportCSVButton>
              </div>
              <div className="search-clear">
                <SearchBar
                  placeholder="Search..."
                  {...searchProps}
                  className="w-100 text-sm"
                />
                <ClearSearchButton
                  {...searchProps}
                  className="focus:outline-none text-sm text-white bg-gray-400"
                />
              </div>
            </TableControls>

            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                // page: 1, // which page you want to show as default
                sizePerPage: 6,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <div className=" m-auto flex flex-col justify-between max-h-screen overflow-auto">
                  <BootstrapTable
                    {...baseProps}
                    classes=" text-sm mb-2"
                    wrapperClasses="table-responsive"
                    data={data}
                    columns={columns}
                    striped
                    {...paginationTableProps}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              )}
            </PaginationProvider>
          </>
        )}
      </ToolkitProvider>
    </PageContainer>
  );
}

export default FMEA;
