import React from "react";
import * as queryTypes from "shared/queryTypes";
import { selectJwtToken } from "store/slices/auth/authSlice";
import { getPartsFailure } from "store/slices/parts/partsSlice";
import { useQuery, useMutation } from "react-query";
import * as api from "store/slices/parts/api";
import { setPartsAlert } from "store/slices/parts/partsSlice";
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import YesCancelButtons from "shared/YesCancelButtons";
import BootstrapTable from "react-bootstrap-table-next";

import useModal from "hooks/useModal";
import useSelectedRowsHook from "hooks/useSelectedRowsHook";
import Dialog from "shared/Dialog";

import DeleteModalTitle from "shared/DeleteModalTitle";

import DeleteModalContent from "shared/DeleteModalContent";
import ModalTitle from "shared/ModalTitle";
import PageLabel from "shared/PageLabel";
import PageContainer from "shared/PageContainer";
import TableControls from "shared/TableControls";
import Spinner from "shared/spinner/Spinner";

import NewPartModalContent from "./dialogs/newPart/NewPartModalContent";

import "./Parts.css";

const { SearchBar, ClearSearchButton } = Search;

function Parts(props) {
  const [selectedRows, selectRow, setSelectedRows] = useSelectedRowsHook({
    rowKey: "system_part_id",
  });
  const [
    showModal,
    setShowModal,
    modalContent,
    setModalContent,
    modalTitle,
    setModalTitle,
    modalFooter,
    setModalFooter,
    toggleModal,
  ] = useModal();

  const dispatch = useDispatch();
  const jwt = useSelector(selectJwtToken);
  const { queries, mutations } = queryTypes;

  const { data, refetch } = useQuery(
    queries.getSystemParts,
    () => api.getSystemParts({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getPartsFailure({ error }));
      },
    }
  );

  const { mutate: submitNewPart } = useMutation(
    mutations.createSystemPart,
    (values) => {
      return api.createPart({ jwt, ...values });
    },
    {
      onSuccess: (data) => {
        dispatch(
          setPartsAlert({
            alert: {
              status: "000", //! special status code for parts alert message
              message: "The system part is created",
              variant: "success",
            },
          })
        );
        setShowModal(false);
        refetch();
      },
    }
  );

  const { mutate: deleteSystemPart } = useMutation(
    mutations.deleteSystemPart,
    ({ ids }) => {
      return api.deletePart({ jwt, ids });
    },
    {
      onSuccess: (data) => {
        const isMulti = data?.affectedRows > 1;
        dispatch(
          setPartsAlert({
            alert: {
              status: "000", //! special status code for parts alert message
              message: `The system part${isMulti ? "s are" : " is"} deleted`,
              variant: "success",
            },
          })
        );
        setShowModal(false);
        console.log(data);
        refetch();
        setSelectedRows({});
      },
    }
  );

  if (!data) {
    return (
      <div className="self-center">
        <Spinner />
      </div>
    );
  }

  const { results } = data;

  const columns = [
    {
      dataField: "system_part_id",
      text: "ID",
      style: () => {
        return { width: "10%", textAlign: "center" };
      },
      headerStyle: () => {
        return {
          width: "10%",
          textAlign: "center",
        };
      },
    },

    {
      dataField: "procedure_step",
      text: "Procedure Step",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
    {
      dataField: "variable",
      text: "Variable",
    },
  ];

  return (
    <PageContainer>
      <PageLabel label="Parts" />
      <ToolkitProvider
        keyField="system_part_id"
        data={results || []}
        columns={columns}
        search
      >
        {({ searchProps, baseProps }) => (
          <>
            <TableControls>
              <div className="control-buttons">
                <div
                  className="btn btn-primary text-sm mr-2 "
                  onClick={() => {
                    setModalTitle(() => <ModalTitle title="Add system part" />);
                    setModalContent(() => (
                      <NewPartModalContent
                        submitNewPart={submitNewPart}
                        setShowModal={setShowModal}
                      />
                    ));

                    setModalFooter(() => null);
                    setShowModal(true);
                  }}
                >
                  New
                </div>
                <div
                  className="btn btn-danger text-sm"
                  onClick={() => {
                    const hasSelectedRows =
                      Object.keys(selectedRows).length > 0;
                    if (hasSelectedRows) {
                      const idsInArray = Object.keys(selectedRows);
                      const ids = idsInArray.join(",");

                      setModalTitle(() => <DeleteModalTitle />);

                      setModalContent(() => (
                        <DeleteModalContent ids={ids} attribute="part" />
                      ));

                      setModalFooter(() => (
                        <YesCancelButtons
                          yesText="Yes do it!"
                          cancelText="Don't"
                          onYes={() => {
                            console.log(`delete parts action ${ids}`);
                            deleteSystemPart({ ids: idsInArray });
                          }}
                          onCancel={() => {
                            setShowModal(false);
                          }}
                        />
                      ));

                      setShowModal(true);
                    } else {
                      dispatch(
                        setPartsAlert({
                          alert: {
                            status: "000", //! special status code
                            message: "Please select at least one row",
                            variant: "warn",
                          },
                        })
                      );
                    }
                  }}
                >
                  Delete
                </div>
              </div>

              <div className="search-clear">
                <SearchBar
                  placeholder="Search..."
                  {...searchProps}
                  className="m-auto text-sm"
                />
                <ClearSearchButton
                  {...searchProps}
                  className="focus:outline-none text-sm text-white bg-gray-400"
                />
              </div>
            </TableControls>

            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                // page: 1, // which page you want to show as default
                sizePerPage: 10,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <div className="m-auto flex flex-col justify-between max-h-screen overflow-auto ">
                  <BootstrapTable
                    {...baseProps}
                    classes=" text-sm mb-2"
                    wrapperClasses="table-responsive"
                    data={results}
                    columns={columns}
                    striped
                    selectRow={selectRow}
                    {...paginationTableProps}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              )}
            </PaginationProvider>
          </>
        )}
      </ToolkitProvider>
      <Dialog
        toggle={toggleModal}
        modal={showModal}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalFooter={modalFooter}
      />
    </PageContainer>
  );
}

export default Parts;
