import React from "react";
import not_found from "./not_found.jpg";

function NotFound(props) {
  return (
    <div>
      <img className="m-auto w-50 h-50" src={not_found} alt="not_found" />
      <div className="text-xs fixed bottom-0 left-1/2 ">
        <a href="http://www.freepik.com">Designed by pch.vector / Freepik</a>
      </div>
    </div>
  );
}

export default NotFound;
