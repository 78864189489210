import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setPersonnelAndPartnerCost,
  selectPersonnelAndPartnerCost,
} from "store/slices/cost/costSlice";

const PersonnelPartnerCostSchema = Yup.object().shape({
  num_of_employees: Yup.number().integer().moreThan(0).required("Required"),
  hours_per_day: Yup.number().moreThan(0).required("Required"),
  num_of_days: Yup.number().moreThan(0).required("Required"),
  average_hour_cost: Yup.number().moreThan(0).required("Required"),
});

function PersonnelPartnerCost({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const personnelAndPartnerCost = useSelector(selectPersonnelAndPartnerCost);
  const { num_of_employees, hours_per_day, num_of_days, average_hour_cost } =
    personnelAndPartnerCost;

  useEffect(() => {
    const total =
      num_of_employees * hours_per_day * num_of_days * average_hour_cost;

    setStepTotal(total);
  }, [num_of_employees, hours_per_day, num_of_days, average_hour_cost]);

  return (
    <div>
      <div className="text-center text-lg my-2">
        Personnel and partners cost
      </div>
      <Formik
        initialValues={{
          num_of_employees,
          hours_per_day,
          num_of_days,
          average_hour_cost,
        }}
        validationSchema={PersonnelPartnerCostSchema}
        onSubmit={async (values) => {
          console.log("values", values);
          dispatch(setPersonnelAndPartnerCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;
          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="num_of_employees"
                  className={sharedClasses.labelClasses}
                >
                  How many employees?
                </label>
                <Field
                  type="number"
                  id="num_of_employees"
                  name="num_of_employees"
                  className={sharedClasses.inputClasses}
                  placeholder="Num of employees"
                />
                <ErrorMessage
                  name="num_of_employees"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="hours_per_day"
                  className={sharedClasses.labelClasses}
                >
                  Hours per day?
                </label>
                <Field
                  type="number"
                  id="hours_per_day"
                  name="hours_per_day"
                  placeholder="Hours per day"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="hours_per_day"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="num_of_days"
                  className={sharedClasses.labelClasses}
                >
                  Number of days?
                </label>
                <Field
                  type="number"
                  id="num_of_days"
                  name="num_of_days"
                  placeholder="Number of days"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="num_of_days"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="num_of_days"
                  className={sharedClasses.labelClasses}
                >
                  Average hour costs (€)?
                </label>
                <Field
                  type="number"
                  id="average_hour_cost"
                  name="average_hour_cost"
                  placeholder="Average hour cost"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="average_hour_cost"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total personnel and partners cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/bottling-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      className={classnames(
                        sharedClasses.btnClasses,
                        "text-white"
                      )}
                      color="success"
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/fixed-assets-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PersonnelPartnerCost;
