import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import RequireAuth from "components/RequireAuth";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Pages from "pages";

import ToastDialog from "components/ToastDialog/ToastDialog";

import("bootstrap/dist/css/bootstrap.css");
import("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function Root(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Pages.Login />} />
          <Route path="/login" element={<Pages.Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Pages.Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/parts"
            element={
              <RequireAuth>
                <Pages.Parts />
              </RequireAuth>
            }
          />
          <Route
            path="/failures"
            element={
              <RequireAuth>
                <Pages.Failures />
              </RequireAuth>
            }
          />
          <Route
            path="/effects"
            element={
              <RequireAuth>
                <Pages.Effects />
              </RequireAuth>
            }
          />
          <Route
            path="/causes"
            element={
              <RequireAuth>
                <Pages.Causes />
              </RequireAuth>
            }
          />
          <Route
            path="/checks"
            element={
              <RequireAuth>
                <Pages.Checks />
              </RequireAuth>
            }
          />
          <Route
            path="/fmea"
            element={
              <RequireAuth>
                <Pages.FMEA />
              </RequireAuth>
            }
          />
          <Route
            path="/fishbone"
            element={
              <RequireAuth>
                <Pages.Fishbone />
              </RequireAuth>
            }
          />
          <Route
            path="/cost_calculation/:step"
            element={
              <RequireAuth>
                <Pages.Cost />
              </RequireAuth>
            }
          />
          <Route
            path="/cost_charts"
            element={
              <RequireAuth>
                <Pages.CostCharts />
              </RequireAuth>
            }
          />
          <Route
            path="/severity"
            element={
              <RequireAuth>
                <Pages.Severity />
              </RequireAuth>
            }
          />

          <Route
            path="*"
            element={
              <RequireAuth>
                <Pages.NotFound />
              </RequireAuth>
            }
          />
        </Routes>
        <ToastDialog />
      </Router>
    </QueryClientProvider>
  );
}

export default Root;
