import React, { useRef } from "react";
import { useSelector } from "react-redux";
import PageLabel from "shared/PageLabel";

import {
  getTotalConsultantCost,
  getTotalEducationCost,
  getTotalUsedEquipmentCost,
  getTotalBottlingCost,
  getTotalPersonnelAndPartnerCost,
  getTotalFixedCost,
  getTotalConsumableCost,
  getTotalPrintedCost,
  getTotalCalibrationAndCheckCost,
  selectOverview,
} from "store/slices/cost/costSlice";
import CostBars from "./CostBars";
import CostPie from "./CostPie";
import QualityCostChart from "./QualityCostChart";
import QualityTeamChart from "./QualityTeamChart";
import DownloadCharts from "./DownloadCharts";
import useGraphCostData from "./useGraphCostData";

function CostCharts(props) {
  const chartsRef = useRef(null);
  const total_bottling_cost = useSelector(getTotalBottlingCost);
  const total_personnel_and_partner_cost = useSelector(
    getTotalPersonnelAndPartnerCost
  );
  const total_fixed_cost = useSelector(getTotalFixedCost);
  const total_used_equipment = useSelector(getTotalUsedEquipmentCost);
  const consumable_cost = useSelector(getTotalConsumableCost);
  const total_education_cost = useSelector(getTotalEducationCost);
  const printed_cost = useSelector(getTotalPrintedCost);
  const callibration_and_check_cost = useSelector(
    getTotalCalibrationAndCheckCost
  );
  const total_consultant_cost = useSelector(getTotalConsultantCost);

  const overview = useSelector(selectOverview);

  const shouldShowGraphs =
    total_bottling_cost &&
    total_personnel_and_partner_cost &&
    total_fixed_cost &&
    total_used_equipment &&
    consumable_cost &&
    total_education_cost &&
    printed_cost &&
    callibration_and_check_cost &&
    total_consultant_cost;

  const graphData = useGraphCostData({
    shouldShowGraphs,
    total_bottling_cost,
    total_personnel_and_partner_cost,
    total_fixed_cost,
    total_used_equipment,
    consumable_cost,
    total_education_cost,
    printed_cost,
    callibration_and_check_cost,
    total_consultant_cost,
  });

  if (!shouldShowGraphs) {
    return (
      <div className="text-center text-red-800 text-lg">
        Please complete cost steps to preview graphs
      </div>
    );
  }

  return (
    <div>
      <PageLabel label="Cost charts" />
      <DownloadCharts chartsRef={chartsRef} />
      <div className="grid grid-cols-2 gap-5 mt-2" ref={chartsRef}>
        <CostBars graphData={graphData} />
        <CostPie graphData={graphData} />
        <QualityCostChart overview={overview} />
        <QualityTeamChart overview={overview} />
      </div>
    </div>
  );
}

export default CostCharts;
