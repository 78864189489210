import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setFixedAssetsCost,
  selectFixedAssetsCost,
} from "store/slices/cost/costSlice";

const FixedAssetsSchema = Yup.object().shape({
  equipment: Yup.number().integer().moreThan(0).required("Required"),
});

function FixedAssetsCosts({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stepTotal, setStepTotal] = useState(0);

  const fixedAssetsCost = useSelector(selectFixedAssetsCost);

  const { equipment } = fixedAssetsCost;

  useEffect(() => {
    const total = equipment;

    setStepTotal(total);
  }, [equipment]);

  return (
    <div>
      <div className="text-center text-lg my-2">Fixed assets cost</div>
      <Formik
        initialValues={{
          equipment,
        }}
        validationSchema={FixedAssetsSchema}
        onSubmit={async (values) => {
          console.log("values", values);
          dispatch(setFixedAssetsCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;
          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="equipment"
                  className={sharedClasses.labelClasses}
                >
                  How much does the fixed equipment costs (€)?
                </label>
                <Field
                  type="number"
                  id="equipment"
                  name="equipment"
                  className={sharedClasses.inputClasses}
                  placeholder="Equipment cost"
                />
                <ErrorMessage
                  name="equipment"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total fixed assets cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/personnel-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/used-equipment-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default FixedAssetsCosts;
