import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setEducationCost,
  selectEducationCost,
} from "store/slices/cost/costSlice";

const EducationCostSchema = Yup.object().shape({
  num_of_persons: Yup.number().integer().moreThan(0).required("Required"),
  cost_per_person: Yup.number().integer().moreThan(0).required("Required"),
});

function EducationCost({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const consumable_cost = useSelector(selectEducationCost);

  const { num_of_persons, cost_per_person } = consumable_cost;

  useEffect(() => {
    const total = num_of_persons * cost_per_person;

    setStepTotal(total);
  }, [num_of_persons, cost_per_person]);

  return (
    <div>
      <div className="text-center text-lg my-2">Education cost</div>
      <Formik
        initialValues={{
          num_of_persons,
          cost_per_person,
        }}
        validationSchema={EducationCostSchema}
        onSubmit={async (values) => {
          dispatch(setEducationCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;

          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="num_of_persons"
                  className={sharedClasses.labelClasses}
                >
                  How many persons are about to be educated?
                </label>
                <Field
                  type="number"
                  id="num_of_persons"
                  name="num_of_persons"
                  className={sharedClasses.inputClasses}
                  placeholder="Number of persons to be educated"
                />
                <ErrorMessage
                  name="num_of_persons"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="cost_per_person"
                  className={sharedClasses.labelClasses}
                >
                  How much does the education per person costs (€)?
                </label>
                <Field
                  type="number"
                  id="cost_per_person"
                  name="cost_per_person"
                  placeholder="education cost per person"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="cost_per_person"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total education cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/consumable-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/printed-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default EducationCost;
