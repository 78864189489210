const useEffectsColumns = () => {
  const columns = [
    {
      dataField: "possible_effect_id",
      text: "Id",
      style: () => {
        return { width: "10%", textAlign: "center" };
      },
      headerStyle: () => {
        return {
          width: "10%",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "effect_description",
      text: "Effect description",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
    {
      dataField: "failure_description",
      text: "Failure description",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
  ];

  return columns;
};

export default useEffectsColumns;
