import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetAuthAlert, selectAuthAlert } from "store/slices/auth/authSlice";
import {
  resetPartsAlert,
  selectPartsAlert,
} from "store/slices/parts/partsSlice";
import {
  resetFailuresAlert,
  selectFailuresAlert,
} from "store/slices/failures/failuresSlice";
import {
  resetEffectsAlert,
  selectEffectsAlert,
} from "store/slices/effects/effectsSlice";
import {
  resetCausesAlert,
  selectCausesAlert,
} from "store/slices/causes/causesSlice";

import {
  resetChecksAlert,
  selectChecksAlert,
} from "store/slices/checks/checksSlice";

import "react-toastify/dist/ReactToastify.css";

const SingleToast = ({ message }) => {
  return <div>{message}</div>;
};

/**
 * alert: {status, message, variant}
 * variant: warn, success, error, info
 * */
function ToastDialog() {
  const authAlert = useSelector(selectAuthAlert);
  const partsAlert = useSelector(selectPartsAlert);
  const failuresAlert = useSelector(selectFailuresAlert);
  const effectsAlert = useSelector(selectEffectsAlert);
  const causesAlert = useSelector(selectCausesAlert);
  const checksAlert = useSelector(selectChecksAlert);

  const message =
    authAlert?.message ||
    partsAlert?.message ||
    failuresAlert?.message ||
    effectsAlert?.message ||
    causesAlert?.message ||
    checksAlert?.message;

  const variant =
    authAlert?.variant ||
    partsAlert?.variant ||
    failuresAlert?.variant ||
    effectsAlert?.variant ||
    causesAlert?.variant ||
    checksAlert?.variant;

  const dispatch = useDispatch();

  const hasMessage = message?.length > 0;

  useEffect(() => {
    if (hasMessage && toast[variant]) {
      toast[variant || "warn"](<SingleToast message={message} />, {
        onClose: () => {
          dispatch(resetAuthAlert());
          dispatch(resetPartsAlert());
          dispatch(resetFailuresAlert());
          dispatch(resetEffectsAlert());
          dispatch(resetCausesAlert());
          dispatch(resetChecksAlert());
        },
      });
    }
  }, [dispatch, message, hasMessage, variant]);
  return <ToastContainer autoClose={5000} />;
}

export default ToastDialog;
