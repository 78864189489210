import axios from "axios";
import * as config from "shared/config";

const login = async ({ username, password }) => {
  try {
    const url = `${config.BASE_PATH}/authenticate?username=${username}&password=${password}`;

    const res = await axios.get(url);

    const { data } = res;
    return data;
  } catch (e) {
    const {
      data: { message },
      status,
    } = e.response;
    console.log("Login Error:", e.response);
    const alert = {
      status,
      message,
      variant: "error",
    };

    throw alert;
  }
};

export default login;
