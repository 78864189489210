import * as colors from "shared/colors";

const useQualityTeamData = ({
  total_personnel_and_partner_cost,
  total_fixed_cost,
  total_used_equipment,
  total_education_cost,
  printed_cost,
  callibration_and_check_cost,
  total_consultant_cost,
}) => {
  const graphData = [
    {
      name: "Personnel and partner",
      cost: total_personnel_and_partner_cost,
      fill: colors.blue600,
    },
    { name: "Fixed", cost: total_fixed_cost, fill: colors.blue400 },
    {
      name: "Usage of equipment",
      cost: total_used_equipment,
      fill: colors.blue200,
    },
    { name: "Education", cost: total_education_cost, fill: colors.blue700 },
    { name: "Printed", cost: printed_cost, fill: colors.blue300 },
    {
      name: "Callibration And Checks",
      cost: callibration_and_check_cost,
      fill: colors.blue400,
    },
    { name: "Consultant", cost: total_consultant_cost, fill: colors.blue100 },
  ];

  return graphData;
};

export default useQualityTeamData;
