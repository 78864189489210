import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import * as sharedClasses from "shared/sharedClasses";

const PartsSchema = Yup.object().shape({
  // system_part_id: Yup.string().required("Required"),
  procedure_step: Yup.string().required("Required"),
  variable: Yup.string().required("Required"),
});

function NewPartModalContent({ submitNewPart, setShowModal }) {
  const isLoading = false;
  return (
    <Formik
      initialValues={{
        procedure_step: "",
        variable: "",
      }}
      validationSchema={PartsSchema}
      onSubmit={async (values) => {
        submitNewPart(values);
      }}
    >
      {({
        errors,
        status,
        touched,
        isSubmitting,
        values,
        submitForm,
        /* and other goodies */
        ...restProps
      }) => {
        return (
          <Form className="bg-gray-50 p-5 rounded-sm ">
            <div className={classnames("flex flex-col")}>
              <label
                htmlFor="procedure_step"
                className={sharedClasses.labelClasses}
              >
                Procedure step
              </label>
              <Field
                type="text"
                id="procedure_step"
                name="procedure_step"
                className={sharedClasses.inputClasses}
              />
              <ErrorMessage
                name="procedure_step"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <label htmlFor="variable" className={sharedClasses.labelClasses}>
                Variable
              </label>
              <Field
                type="text"
                id="variable"
                name="variable"
                className={sharedClasses.inputClasses}
              />
              <ErrorMessage
                name="variable"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <div>
                <Button
                  color="success"
                  className={sharedClasses.btnClasses}
                  onClick={submitForm}
                >
                  {isLoading ? "Wait" : "Save"}
                </Button>
                <Button
                  color="warning"
                  className={sharedClasses.btnClasses}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewPartModalContent;
