import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as sharedClasses from "shared/sharedClasses";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setBottlingCost,
  selectBottlingCost,
} from "store/slices/cost/costSlice";

const BottlingCostSchema = Yup.object().shape({
  kg: Yup.number().moreThan(0).required("Required"),
  lt_per_bottle: Yup.number().moreThan(0).required("Required"),
  cost_per_bottle: Yup.number().moreThan(0).required("Required"),
});

function BottlingCost({ next, push }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);
  const [numOfBottles, setNumOfBottles] = useState(0);
  const bottlingCost = useSelector(selectBottlingCost);

  useEffect(() => {
    const total = bottlingCost.num_of_bottles * bottlingCost.cost_per_bottle;

    setStepTotal(total);
    setNumOfBottles(bottlingCost.num_of_bottles);
  }, [bottlingCost.cost_per_bottle, bottlingCost.num_of_bottles]);

  return (
    <div className="w-full">
      <div className="text-center text-lg my-2">Bottling cost</div>
      <Formik
        initialValues={{
          kg: bottlingCost.kg,
          lt_per_bottle: bottlingCost.lt_per_bottle,
          cost_per_bottle: bottlingCost.cost_per_bottle,
        }}
        validationSchema={BottlingCostSchema}
        onSubmit={async (values, ...rest) => {
          const { cost_per_bottle, kg, lt_per_bottle } = values;
          const num_of_bottles = kg / lt_per_bottle;

          dispatch(
            setBottlingCost({
              cost_per_bottle,
              kg,
              lt_per_bottle,
              num_of_bottles,
            })
          );
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;
          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label htmlFor="kg" className={sharedClasses.labelClasses}>
                  How many kgrs?
                </label>
                <Field
                  type="number"
                  id="kg"
                  name="kg"
                  className={sharedClasses.inputClasses}
                  placeholder="kg"
                />
                <ErrorMessage
                  name="kg"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="lt_per_bottle"
                  className={sharedClasses.labelClasses}
                >
                  Litters per bottle?
                </label>
                <Field
                  type="number"
                  id="lt_per_bottle"
                  name="lt_per_bottle"
                  placeholder="Litters per bottle"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="lt_per_bottle"
                  component="div"
                  className={sharedClasses.errorClasses}
                />
                <label
                  htmlFor="cost_per_bottle"
                  className={sharedClasses.labelClasses}
                >
                  Cost per bottle (€)?
                </label>
                <Field
                  type="number"
                  id="cost_per_bottle"
                  name="cost_per_bottle"
                  placeholder="cost per bottle"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="cost_per_bottle"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Quantity of bottles: {numOfBottles.toFixed(2)}
                </div>
                <div className="my-2">Total bottling cost (€): {stepTotal}</div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      className={classnames(
                        sharedClasses.btnClasses,
                        "text-white"
                      )}
                      color="success"
                      onClick={() => {
                        const url = "/cost_calculation/personnel-cost";
                        next();
                        navigate(url);
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default BottlingCost;
