import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

const savePDF = (ref) => {
  const chartsNode = ref.current;

  domtoimage
    .toPng(chartsNode)
    .then(function (dataUrl) {
      const img = new Image();
      img.src = dataUrl;
      const doc = new jsPDF("landscape");
      const width = doc.internal.pageSize.width;
      const height = doc.internal.pageSize.height;
      doc.addImage(img, "PNG", 0, 0, width, height);
      doc.save("charts.pdf");
    })
    .catch(function (error) {
      console.error("oops, something went wrong!", error);
    });
};

const DownloadCharts = ({ chartsRef }) => {
  return (
    <div className="text-right">
      <FontAwesomeIcon
        icon={faDownload}
        size="lg"
        className="text-blue-600 cursor-pointer"
        onClick={() => {
          savePDF(chartsRef);
        }}
        data-tip="Click to download charts"
      />
      <ReactTooltip />
    </div>
  );
};

export default DownloadCharts;
