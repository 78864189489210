import React from "react";
import { Tooltip, PieChart, Pie } from "recharts";

function CostPie({ graphData }) {
  return (
    <div id="pieChart" className="bg-blue-900 rounded ">
      <div className="text-center text-lg my-2 text-white">Cost analysis</div>
      <PieChart width={500} height={200} margin={{ left: 150 }}>
        <Pie
          dataKey="cost"
          isAnimationActive={false}
          data={graphData}
          cx={100}
          cy={100}
          outerRadius={60}
          label
        />
        <Tooltip />
      </PieChart>
    </div>
  );
}

export default CostPie;
