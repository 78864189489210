import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setConsumableCost,
  selectConsumableCost,
} from "store/slices/cost/costSlice";

const ConsumableCostCostSchema = Yup.object().shape({
  office_consumable_cost: Yup.number()
    .integer()
    .moreThan(0)
    .required("Required"),
  maintenance_and_improvement_cost: Yup.number()
    .integer()
    .moreThan(0)
    .required("Required"),
});

function ConsumableCost({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const consumable_cost = useSelector(selectConsumableCost);

  const { office_consumable_cost, maintenance_and_improvement_cost } =
    consumable_cost;

  useEffect(() => {
    const total = office_consumable_cost + maintenance_and_improvement_cost;

    setStepTotal(total);
  }, [office_consumable_cost, maintenance_and_improvement_cost]);

  return (
    <div>
      <div className="text-center text-lg my-2">
        Consumable and spare parts cost
      </div>
      <Formik
        initialValues={{
          office_consumable_cost,
          maintenance_and_improvement_cost,
        }}
        validationSchema={ConsumableCostCostSchema}
        onSubmit={async (values) => {
          dispatch(setConsumableCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;

          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="office_consumable_cost"
                  className={sharedClasses.labelClasses}
                >
                  What does office consumable costs (€)?
                </label>
                <Field
                  type="number"
                  id="office_consumable_cost"
                  name="office_consumable_cost"
                  className={sharedClasses.inputClasses}
                  placeholder="Office and consumable costs"
                />
                <ErrorMessage
                  name="office_consumable_cost"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <label
                  htmlFor="maintenance_and_improvement_cost"
                  className={sharedClasses.labelClasses}
                >
                  What does maintenance and improvements costs (€)?
                </label>
                <Field
                  type="number"
                  id="maintenance_and_improvement_cost"
                  name="maintenance_and_improvement_cost"
                  placeholder="Maintenance and improvement costs"
                  className={sharedClasses.inputClasses}
                />
                <ErrorMessage
                  name="maintenance_and_improvement_cost"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total consumable and spare parts cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/used-equipment-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/education-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ConsumableCost;
