import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import * as sharedClasses from "shared/sharedClasses";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import * as queryTypes from "shared/queryTypes";
import Spinner from "shared/spinner/Spinner";

import * as api from "store/slices/failures/api";
import { selectJwtToken } from "store/slices/auth/authSlice";
import { getFailuresFailure } from "store/slices/failures/failuresSlice";
import Select from "react-select";
import _ from "lodash";

const CausesSchema = Yup.object().shape({
  cause_description: Yup.string().required("Required"),
  possible_failure_id: Yup.string().required("Required"),
});

function NewCauseModalContent({ submitNewCause, setShowModal }) {
  const jwt = useSelector(selectJwtToken);
  const dispatch = useDispatch();

  const { queries } = queryTypes;
  //get failures
  const { data } = useQuery(
    queries.getFailures,
    () => api.getFailures({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getFailuresFailure({ error }));
      },
    }
  );

  if (!data) {
    <div className="self-center">
      <Spinner />
    </div>;
  }

  const displayFailures = data?.results.map((failure) => ({
    value: failure.possible_failure_id,
    label: failure.failure_description,
  }));

  const isLoading = false;
  return (
    <Formik
      initialValues={{
        possible_failure_id: "",
        cause_description: "",
      }}
      validationSchema={CausesSchema}
      onSubmit={async (values) => {
        submitNewCause(values);
      }}
    >
      {({
        errors,
        status,
        touched,
        isSubmitting,
        values,
        submitForm,
        /* and other goodies */
        ...restProps
      }) => {
        return (
          <Form className="bg-gray-50 p-5 rounded-sm ">
            <div className={classnames("flex flex-col")}>
              <label
                htmlFor="cause_description"
                className={sharedClasses.labelClasses}
              >
                Causes description
              </label>
              <Field
                type="text"
                id="cause_description"
                name="cause_description"
                className={sharedClasses.inputClasses}
              />
              <ErrorMessage
                name="cause_description"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <label
                htmlFor="possible_failure_id"
                className={sharedClasses.labelClasses}
              >
                Failure
              </label>
              <Field
                type="number"
                id="possible_failure_id"
                name="possible_failure_id"
                className={sharedClasses.inputClasses}
              >
                {({ field, form, ...rest }) => {
                  console.log("rest", rest);
                  return (
                    <Select
                      options={displayFailures}
                      onChange={(option, action) => {
                        if (
                          option &&
                          !_.isNull(option.value) &&
                          !_.isUndefined(option.value)
                        ) {
                          form.setFieldValue(field.name, option.value);
                        } else if (option) {
                          // isMulti case
                          form.setFieldValue(field.name, option);
                        } else {
                          form.setFieldValue(field.name, "");
                        }
                      }}
                    />
                  );
                }}
              </Field>
              <ErrorMessage
                name="possible_failure_id"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <div>
                <Button
                  color="success"
                  className={sharedClasses.btnClasses}
                  onClick={submitForm}
                >
                  {isLoading ? "Wait" : "Save"}
                </Button>
                <Button
                  color="warning"
                  className={sharedClasses.btnClasses}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewCauseModalContent;
