import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { createBrowserHistory } from "history";

import * as rootReducer from "./slices";

export const history = createBrowserHistory();

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalstorage = (clear = false) => {
  try {
    if (clear) {
      localStorage.clear();
    }
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const persistedState = loadFromLocalstorage();

const store = configureStore({
  reducer: { ...rootReducer, router: connectRouter(history) },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
  devTools: true,
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

export default store;
