import React from "react";
import { Wizard } from "react-albus";
import PageLabel from "shared/PageLabel";
import PageContainer from "shared/PageContainer";

import StepWizard from "./StepWizard";

function Cost(props) {
  return (
    <PageContainer>
      <PageLabel label="Cost" />
      <Wizard
        render={(props) => {
          return <StepWizard {...props} />;
        }}
      />
    </PageContainer>
  );
}

export default Cost;
