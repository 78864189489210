import * as colors from "shared/colors";

const useQualityCostData = ({ totalQualityTeamCost, totalProductionCost }) => {
  const pieData = [
    { name: "Quality team", cost: totalQualityTeamCost, fill: colors.blue600 },
    {
      name: "Production cost",
      cost: totalProductionCost,
      fill: colors.blue400,
    },
  ];

  return pieData;
};

export default useQualityCostData;
