import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DeleteModalTitle(props) {
  return (
    <div className="flex flex-row items-center">
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="xs"
        className="text-red-600 mr-2"
      />
      Delete confirmation
    </div>
  );
}

export default DeleteModalTitle;
