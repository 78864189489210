import React from "react";
import * as queryTypes from "shared/queryTypes";
import { selectJwtToken } from "store/slices/auth/authSlice";
import * as api from "store/slices/severity/api";
import { useQuery } from "react-query";

import { getSeverityFailure } from "store/slices/severity/severitySlice";

import { useSelector, useDispatch } from "react-redux";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import PageLabel from "shared/PageLabel";
import PageContainer from "shared/PageContainer";
import TableControls from "shared/TableControls";
import Spinner from "shared/spinner/Spinner";

import "./Severity.css";

const { SearchBar, ClearSearchButton } = Search;

function Severity(props) {
  const dispatch = useDispatch();
  const jwt = useSelector(selectJwtToken);
  const { queries } = queryTypes;

  const { data } = useQuery(
    queries.getSeverity,
    () => api.getSeverity({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getSeverityFailure({ error }));
      },
    }
  );

  if (!data) {
    return (
      <div className="self-center">
        <Spinner />
      </div>
    );
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      style: () => {
        return { width: "10%", textAlign: "center" };
      },
      headerStyle: () => {
        return {
          width: "10%",
          textAlign: "center",
        };
      },
    },
    {
      dataField: "severity",
      text: "Severity",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
    {
      dataField: "criteria",
      text: "Criteria",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
    {
      dataField: "significance",
      text: "Significance",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
    {
      dataField: "estimation_score",
      text: "Estimation",
      style: () => {
        return { overflowWrap: "break-word" };
      },
      headerStyle: () => {
        return { overflowWrap: "break-word" };
      },
    },
  ];

  return (
    <PageContainer>
      <PageLabel label="Severity" />
      <ToolkitProvider keyField="id" data={data || []} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <>
            <TableControls>
              <SearchBar
                placeholder="Search..."
                {...searchProps}
                className="table-container m-auto text-sm"
              />
              <ClearSearchButton
                {...searchProps}
                className="focus:outline-none text-sm text-white bg-gray-400"
              />
            </TableControls>

            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                // page: 1, // which page you want to show as default
                sizePerPage: 6,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <div className="m-auto flex flex-col justify-between max-h-screen overflow-auto max-h-screen overflow-auto">
                  <BootstrapTable
                    {...baseProps}
                    classes=" text-sm mb-2"
                    wrapperClasses="table-responsive"
                    columns={columns}
                    striped
                    {...paginationTableProps}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              )}
            </PaginationProvider>
          </>
        )}
      </ToolkitProvider>
    </PageContainer>
  );
}

export default Severity;
