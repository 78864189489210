import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  jwt: "",
  username: "",
  alert: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { username, password } = action.payload;
      state.loggedIn = username === "administrator" && password === "NexVisor";
      state.firstScreen = false;
      state.alert = "";
    },
    loginSuccess: (state, action) => {
      const { jwt } = action.payload;
      state.jwt = jwt;
      state.firstScreen = false;
      state.loggedIn = true;
    },
    loginFailure: (state, action) => {
      const { alert } = action.payload;
      state.alert = alert;
    },
    logout: (state, action) => {
      state.loggedIn = false;
      state.firstScreen = true;
      state.jwt = "";
      state.username = "";

      //! it will be used for forcefull logout
      if (action.payload) {
        const { alert } = action.payload;
        state.alert = alert;
      }
    },
    resetAuthAlert: (state, action) => {
      state.alert = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, loginSuccess, resetAuthAlert, loginFailure } =
  authSlice.actions;

export const selectLoggedIn = ({ auth }) => {
  return auth?.loggedIn;
};

export const selectJwtToken = ({ auth }) => {
  return auth?.jwt;
};

export const selectAuthAlert = ({ auth }) => {
  return auth?.alert;
};

export default authSlice.reducer;
