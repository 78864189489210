import React from "react";
import FishboneChart from "fishbone-chart";
import * as queryTypes from "shared/queryTypes";
import { selectJwtToken } from "store/slices/auth/authSlice";
import PageLabel from "shared/PageLabel";
import PageContainer from "shared/PageContainer";
import Spinner from "shared/spinner/Spinner";

import { getFishboneFailure } from "store/slices/fishbone/fishboneSlice";
import { useQuery } from "react-query";
import * as api from "store/slices/fishbone/api";

import { useSelector, useDispatch } from "react-redux";
import "./Fishbone.css";

function Fishbone(props) {
  const dispatch = useDispatch();
  const jwt = useSelector(selectJwtToken);
  const { queries } = queryTypes;

  const { data } = useQuery(
    queries.getFailures,
    () => api.getFishbone({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getFishboneFailure({ error }));
      },
    }
  );

  if (!data) {
    return (
      <div className="self-center">
        <Spinner />
      </div>
    );
  }

  return (
    <PageContainer>
      <PageLabel label="Fishbone" />
      <div className="my-10 fishbone">
        <FishboneChart data={data?.fishbone || {}} className="test" />
      </div>
    </PageContainer>
  );
}

export default Fishbone;
