import React from "react";
import { Tooltip, PieChart, Pie } from "recharts";
import useQualityCostData from "./useQualityCostData";

function QualityCostChart({ overview }) {
  const { totalProductionCost, totalQualityTeamCost } = overview;

  const qualityCostData = useQualityCostData({
    totalQualityTeamCost,
    totalProductionCost,
  });

  return (
    <div id="qualityCostChart" className="bg-blue-900 rounded ">
      <div className="text-center text-lg my-2 text-white">Quality cost</div>
      <PieChart width={500} height={200} margin={{ left: 150 }}>
        <Pie
          dataKey="cost"
          isAnimationActive={false}
          data={qualityCostData}
          cx={100}
          cy={100}
          outerRadius={60}
          fill="#3C1518"
          label
        />
        <Tooltip />
      </PieChart>
    </div>
  );
}

export default QualityCostChart;
