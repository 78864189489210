export const blue900 = "#022f6b";
export const blue800 = "#064291";
export const blue700 = "#04425a";
export const blue600 = "#005acd";
export const blue500 = "#003f92";
export const blue400 = "#0093cb";
export const blue300 = "#36b1e2";
export const blue200 = "#6dd7fd";
export const blue100 = "#bef0ff";

export const white = "#fff";
