import { useLocation, Navigate } from "react-router-dom";
import { selectLoggedIn } from "store/slices/auth/authSlice";
import { useSelector } from "react-redux";
import Sidebar from "components/Sidebar/Sidebar";

import "./RequireAuth.css";

function RequireAuth({ children }) {
  const authed = useSelector(selectLoggedIn);
  const location = useLocation();

  return authed === true ? (
    <div className="flex flex-row h-screen w-screen">
      <Sidebar />
      <div className="w-10/12 bg-gray-200 h-full flex justify-center items-start">
        {children}
      </div>
    </div>
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default RequireAuth;
