import { classnames } from "tailwindcss-classnames";

const btnClasses = classnames("mr-2 my-2");
const inputClasses = classnames(
  " border border-gray-400 rounded-sm p-1 focus:outline-none"
);
const labelClasses = classnames("my-2");
const errorClasses = classnames("text-red-500");

export { btnClasses, inputClasses, labelClasses, errorClasses };
