import { createSlice } from "@reduxjs/toolkit";
import { logout } from "store/slices/auth/authSlice";

const initialState = {
  alert: {},
  bottling_cost: {
    kg: 0,
    lt_per_bottle: 0,
    cost_per_bottle: 0,
    num_of_bottles: 0,
  },
  personnel_and_partner_cost: {
    num_of_employees: 0,
    hours_per_day: 0,
    num_of_days: 0,
    average_hour_cost: 0,
  },
  fixed_assets_cost: {
    equipment: 0,
  },
  used_equipment_cost: {
    used_equipment: 0,
  },
  consumable_cost: {
    office_consumable_cost: 0,
    maintenance_and_improvement_cost: 0,
  },
  education_cost: {
    num_of_persons: 0,
    cost_per_person: 0,
  },
  printed_cost: {
    printed: 0,
  },
  callibration_and_check_cost: {
    callibration: 0,
  },
  consultant_cost: {
    consultant: 0,
  },
};

export const costSlice = createSlice({
  name: "cost",
  initialState,
  reducers: {
    setBottlingCost: (state, action) => {
      const { kg, lt_per_bottle, cost_per_bottle, num_of_bottles } =
        action.payload;

      state.bottling_cost = {
        kg,
        lt_per_bottle,
        cost_per_bottle,
        num_of_bottles,
      };
    },
    setPersonnelAndPartnerCost: (state, action) => {
      const {
        num_of_employees,
        hours_per_day,
        num_of_days,
        average_hour_cost,
      } = action.payload;

      state.personnel_and_partner_cost = {
        num_of_employees,
        hours_per_day,
        num_of_days,
        average_hour_cost,
      };
    },
    setFixedAssetsCost: (state, action) => {
      const { equipment } = action.payload;

      state.fixed_assets_cost = {
        equipment,
      };
    },
    setUsedEquipmentCost: (state, action) => {
      const { used_equipment } = action.payload;

      state.used_equipment_cost = {
        used_equipment,
      };
    },
    setConsumableCost: (state, action) => {
      const { office_consumable_cost, maintenance_and_improvement_cost } =
        action.payload;

      state.consumable_cost = {
        office_consumable_cost,
        maintenance_and_improvement_cost,
      };
    },
    setEducationCost: (state, action) => {
      const { num_of_persons, cost_per_person } = action.payload;

      state.education_cost = {
        num_of_persons,
        cost_per_person,
      };
    },
    setPrintedCost: (state, action) => {
      const { printed } = action.payload;

      state.printed_cost = {
        printed,
      };
    },
    setCallibrationAndCheckCost: (state, action) => {
      const { callibration } = action.payload;

      state.callibration_and_check_cost = {
        callibration,
      };
    },
    setConsultantCost: (state, action) => {
      const { consultant } = action.payload;

      state.consultant_cost = {
        consultant,
      };
    },
  },
  // https://redux-toolkit.js.org/api/createslice/#customizing-generated-action-creators
  extraReducers: (builder) => {
    // empty state on logout
    builder
      .addCase(logout({}).type, (_state, _action) => {
        return { ...initialState };
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase((state, _action) => state);
  },
});

// Action creators are generated for each case reducer function
export const {
  setBottlingCost,
  setPersonnelAndPartnerCost,
  setFixedAssetsCost,
  setUsedEquipmentCost,
  setConsumableCost,
  setEducationCost,
  setPrintedCost,
  setCallibrationAndCheckCost,
  setConsultantCost,
} = costSlice.actions;

export const selectBottlingCost = ({ cost }) => {
  return cost?.bottling_cost;
};

export const selectPersonnelAndPartnerCost = ({ cost }) => {
  return cost?.personnel_and_partner_cost;
};

export const selectFixedAssetsCost = ({ cost }) => {
  return cost?.fixed_assets_cost;
};

export const selectUsedEquipmentCost = ({ cost }) => {
  return cost?.used_equipment_cost;
};

export const selectConsumableCost = ({ cost }) => {
  return cost?.consumable_cost;
};

export const selectEducationCost = ({ cost }) => {
  return cost?.education_cost;
};

export const selectPrintedCost = ({ cost }) => {
  return cost?.printed_cost;
};

export const selectCallibrationAndCheckCost = ({ cost }) => {
  return cost?.callibration_and_check_cost;
};
export const selectConsultantCost = ({ cost }) => {
  return cost?.consultant_cost;
};

/* for charts and not only */

export const getTotalBottlingCost = ({ cost }) => {
  const { kg, lt_per_bottle, cost_per_bottle } = cost?.bottling_cost;
  const total = (kg / lt_per_bottle) * cost_per_bottle;
  return total;
};

export const getTotalPersonnelAndPartnerCost = ({ cost }) => {
  const { num_of_employees, hours_per_day, num_of_days, average_hour_cost } =
    cost?.personnel_and_partner_cost;

  const total =
    num_of_employees * hours_per_day * num_of_days * average_hour_cost;
  return total;
};

export const getTotalFixedCost = ({ cost }) => {
  const { equipment } = cost?.fixed_assets_cost;

  const total = equipment;
  return total;
};

export const getTotalUsedEquipmentCost = ({ cost }) => {
  const { used_equipment } = cost?.used_equipment_cost;
  const total = used_equipment;
  return total;
};

export const getTotalConsumableCost = ({ cost }) => {
  const { office_consumable_cost, maintenance_and_improvement_cost } =
    cost?.consumable_cost;
  const total = office_consumable_cost + maintenance_and_improvement_cost;
  return total;
};

export const getTotalEducationCost = ({ cost }) => {
  const { num_of_persons, cost_per_person } = cost?.education_cost;
  const total = num_of_persons * cost_per_person;
  return total;
};

export const getTotalPrintedCost = ({ cost }) => {
  const { printed } = cost?.printed_cost;
  const total = printed;
  return total;
};

export const getTotalCalibrationAndCheckCost = ({ cost }) => {
  const { callibration } = cost?.callibration_and_check_cost;
  const total = callibration;
  return total;
};

export const getTotalConsultantCost = ({ cost }) => {
  const { consultant } = cost?.consultant_cost;
  const total = consultant;
  return total;
};

export const selectOverview = ({ cost }) => {
  const { kg, lt_per_bottle, cost_per_bottle, num_of_bottles } =
    cost.bottling_cost;

  const { num_of_employees, hours_per_day, num_of_days, average_hour_cost } =
    cost.personnel_and_partner_cost;

  const { equipment } = cost.fixed_assets_cost;
  const { used_equipment } = cost.used_equipment_cost;
  const { office_consumable_cost, maintenance_and_improvement_cost } =
    cost.consumable_cost;

  const { num_of_persons, cost_per_person } = cost.education_cost;
  const { printed } = cost.printed_cost;
  const { callibration } = cost.callibration_and_check_cost;
  const { consultant } = cost.consultant_cost;

  const total_personnel_and_partner_cost =
    num_of_employees * hours_per_day * num_of_days * average_hour_cost;

  const total_consumable_cost =
    office_consumable_cost + maintenance_and_improvement_cost;

  const total_education_cost = num_of_persons * cost_per_person;

  const totalQualityTeamCost =
    total_personnel_and_partner_cost +
    equipment +
    used_equipment +
    total_consumable_cost +
    total_education_cost +
    printed +
    callibration +
    consultant;

  const totalProductionCost =
    totalQualityTeamCost + (kg / lt_per_bottle) * cost_per_bottle;

  const totalProductionPercentagePerBottle =
    totalProductionCost / num_of_bottles;

  let totalQualityProductionCost = totalQualityTeamCost / totalProductionCost;

  return {
    totalQualityTeamCost,
    totalProductionCost,
    totalProductionPercentagePerBottle,
    totalQualityProductionCost,
  };
};

export default costSlice.reducer;
