import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setUsedEquipmentCost,
  selectUsedEquipmentCost,
} from "store/slices/cost/costSlice";

const UsedEquipmentSchema = Yup.object().shape({
  used_equipment: Yup.number().integer().moreThan(0).required("Required"),
});

function UsedEquipmentCost({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const used_equipment_cost = useSelector(selectUsedEquipmentCost);

  const { used_equipment } = used_equipment_cost;

  useEffect(() => {
    const total = used_equipment;

    setStepTotal(total);
  }, [used_equipment]);

  return (
    <div>
      <div className="text-center text-lg my-2">Used equipment cost</div>
      <Formik
        initialValues={{
          used_equipment,
        }}
        validationSchema={UsedEquipmentSchema}
        onSubmit={async (values) => {
          dispatch(setUsedEquipmentCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;

          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="used_equipment"
                  className={sharedClasses.labelClasses}
                >
                  How much does the used equipment costs (€)?
                </label>
                <Field
                  type="number"
                  id="used_equipment"
                  name="used_equipment"
                  className={sharedClasses.inputClasses}
                  placeholder="Equipment cost"
                />
                <ErrorMessage
                  name="used_equipment"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total used equipment cost (€): {stepTotal}
                </div>
                <div>
                  <Button
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/fixed-assets-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/consumable-cost");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UsedEquipmentCost;
