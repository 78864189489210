import { useState } from "react";
const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const toggleModal = () => setShowModal(!showModal);

  return [
    showModal,
    setShowModal,
    modalContent,
    setModalContent,
    modalTitle,
    setModalTitle,
    modalFooter,
    setModalFooter,
    toggleModal,
  ];
};

export default useModal;
