import React from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const url =
    "https://apothesis.eap.gr/bitstream/repo/40139/1/%CE%94%CE%95%20%CE%91%CE%93%CE%93%CE%95%CE%9B%CE%91%CE%9A%CE%97%CE%A3%20%CE%A7%CE%91%CE%A1%CE%91%CE%9B%CE%91%CE%9C%CE%A0%CE%9F%CE%A3%202018-1.pdf";
  return (
    <div className="flex flex-col self-center bg-gray-400 rounded text-white w-50 p-5">
      <div className="text-xl text-center">Welcome to studying quality!</div>
      <div className="text-base text-justify m-2">
        This dashboard was developed for the purpose of the{" "}
        <a href={url}>Master Theses</a> of Charalampos Aggelakis.
        <div className="mt-2">
          It's main purpose is to provide the necessary means to analyze
          possible causes and failures of a fully olive oil production system .
          <div className="mt-2">
            The user can follow the steps provided in the menu to produce:
          </div>
          <ul className="list-disc mt-2">
            <li>
              <Link to="/fishbone">Fishbone diagrams</Link>
            </li>
            <li>
              <Link to="/fmea">FMEA quality data sheet</Link>
            </li>
            <li>
              <Link to="/cost_charts">Chart reports</Link>
            </li>
            <li>
              {" "}
              <Link to="/severity">
                Overview the static data for s,p,d tables
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
