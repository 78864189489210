export const queries = {
  getSystemParts: "GET_SYSTEM_PARTS",
  getFailures: "GET_FAILURES",
  getEffects: "GET_EFFECTS",
  getCauses: "GET_CAUSES",
  getChecks: "GET_CHECKS",
  getFmeaScores: "GET_FMEA_SCORES",
  getFishbone: "GET_FISHBONE",
  getSeverity: "GET_SEVERITY",
};

export const mutations = {
  login: "LOGIN",
  createSystemPart: "CREATE_SYSTEM_PART",
  deleteSystemPart: "DELETE_SYSTEM_PART",
  createFailure: "CREATE_FAILURE",
  deleteFailures: "DELETE_FAILURES",
  createEffect: "CREATE_EFFECT",
  deleteEffects: "DELETE_EFFECTS",
  createCause: "CREATE_CAUSE",
  deleteCauses: "DELETE_CAUSES",
  createCheck: "CREATE_CHECK",
  deleteChecks: "DELETE_CHECKS",
};
