import React from "react";
import { Button } from "reactstrap";

function YesCancelButtons({ onYes, onCancel, yesText, cancelText }) {
  return (
    <div>
      <Button color="success" className="mr-2" onClick={onYes}>
        {yesText ? yesText : "Yes"}
      </Button>
      <Button color="warning" className="mr-2" onClick={onCancel}>
        {cancelText ? cancelText : "Cancel"}
      </Button>
    </div>
  );
}

export default YesCancelButtons;
