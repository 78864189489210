import * as colors from "shared/colors";

const useGraphCostData = ({
  shouldShowGraphs,
  total_bottling_cost,
  total_personnel_and_partner_cost,
  total_fixed_cost,
  total_used_equipment,
  consumable_cost,
  total_education_cost,
  printed_cost,
  callibration_and_check_cost,
  total_consultant_cost,
}) => {
  if (!shouldShowGraphs) {
    return [];
  }

  const graphCostData = [
    { name: "Bottling", cost: total_bottling_cost, fill: colors.blue100 },
    {
      name: "Personnel and partner",
      cost: total_personnel_and_partner_cost,
      fill: colors.blue200,
    },
    { name: "Fixed", cost: total_fixed_cost, fill: colors.blue300 },
    {
      name: "Usage of equipment",
      cost: total_used_equipment,
      fill: colors.blue400,
    },
    { name: "Consumable", cost: consumable_cost, fill: colors.blue500 },
    { name: "Education", cost: total_education_cost, fill: colors.blue600 },
    { name: "Printed", cost: printed_cost, fill: colors.blue700 },
    {
      name: "Callibration And Checks",
      cost: callibration_and_check_cost,
      fill: colors.blue800,
    },
    { name: "Consultant", cost: total_consultant_cost, fill: colors.blue900 },
  ];

  return graphCostData;
};

export default useGraphCostData;
