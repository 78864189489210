import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import * as sharedClasses from "shared/sharedClasses";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import * as queryTypes from "shared/queryTypes";

import * as api from "store/slices/parts/api";
import { selectJwtToken } from "store/slices/auth/authSlice";
import { getPartsFailure } from "store/slices/parts/partsSlice";
import Select from "react-select";
import _ from "lodash";
import Spinner from "shared/spinner/Spinner";

const FailuresSchema = Yup.object().shape({
  failure_description: Yup.string().required("Required"),
  system_part_id: Yup.number().required("Required"),
});

function NewFailureModalContent({ submitNewFailure, setShowModal }) {
  const isLoading = false;

  const jwt = useSelector(selectJwtToken);
  const dispatch = useDispatch();
  const { queries } = queryTypes;

  //get parts
  const { data } = useQuery(
    queries.getSystemParts,
    () => api.getSystemParts({ jwt }),
    {
      onError: (error) => {
        console.log("error", error);
        dispatch(getPartsFailure({ error }));
      },
    }
  );

  if (!data) {
    return (
      <div className="self-center">
        <Spinner />
      </div>
    );
  }

  const displayParts = data?.results.map((part) => ({
    value: part.system_part_id,
    label: `${part.procedure_step} - ${part.variable}`,
  }));
  return (
    <Formik
      initialValues={{
        failure_description: "",
        system_part_id: "",
      }}
      validationSchema={FailuresSchema}
      onSubmit={async (values) => {
        submitNewFailure(values);
      }}
    >
      {({
        errors,
        status,
        touched,
        isSubmitting,
        values,
        submitForm,
        /* and other goodies */
        ...restProps
      }) => {
        return (
          <Form className="bg-gray-50 p-5 rounded-sm ">
            <div className={classnames("flex flex-col")}>
              <label
                htmlFor="failure_description"
                className={sharedClasses.labelClasses}
              >
                Failure descripion
              </label>
              <Field
                type="text"
                id="failure_description"
                name="failure_description"
                className={sharedClasses.inputClasses}
              />
              <ErrorMessage
                name="failure_description"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <label
                htmlFor="system_part_id"
                className={sharedClasses.labelClasses}
              >
                System part id
              </label>
              <Field
                type="number"
                id="system_part_id"
                name="system_part_id"
                className={sharedClasses.inputClasses}
              >
                {({ field, form, ...rest }) => {
                  console.log("rest", rest);
                  return (
                    <Select
                      options={displayParts}
                      onChange={(option, action) => {
                        if (
                          option &&
                          !_.isNull(option.value) &&
                          !_.isUndefined(option.value)
                        ) {
                          form.setFieldValue(field.name, option.value);
                        } else if (option) {
                          // isMulti case
                          form.setFieldValue(field.name, option);
                        } else {
                          form.setFieldValue(field.name, "");
                        }
                      }}
                    />
                  );
                }}
              </Field>
              <ErrorMessage
                name="system_part_id"
                component="div"
                className={sharedClasses.errorClasses}
              />

              <div>
                <Button
                  color="success"
                  className={sharedClasses.btnClasses}
                  onClick={submitForm}
                >
                  {isLoading ? "Wait" : "Save"}
                </Button>
                <Button
                  color="warning"
                  className={sharedClasses.btnClasses}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewFailureModalContent;
