import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import * as sharedClasses from "shared/sharedClasses";
import * as Yup from "yup";
import { classnames } from "tailwindcss-classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  setConsultantCost,
  selectConsultantCost,
} from "store/slices/cost/costSlice";

const ConsultantCosttSchema = Yup.object().shape({
  consultant: Yup.number().integer().moreThan(0).required("Required"),
});

function ConsultantCost({ next, previous }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stepTotal, setStepTotal] = useState(0);

  const consultant_cost = useSelector(selectConsultantCost);

  const { consultant } = consultant_cost;

  useEffect(() => {
    const total = consultant;

    setStepTotal(total);
  }, [consultant]);

  return (
    <div>
      <div className="text-center text-lg my-2">Consultant cost</div>
      <Formik
        initialValues={{
          consultant,
        }}
        validationSchema={ConsultantCosttSchema}
        onSubmit={async (values) => {
          dispatch(setConsultantCost({ ...values }));
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          submitForm,
          submitCount,
          /* and other goodies */
          ...restProps
        }) => {
          const hasErrors = Object.keys(errors).length > 0;
          const isSubmitted = submitCount >= 1 && !hasErrors;
          const hasTotal = stepTotal > 0;

          return (
            <Form className="bg-gray-50 p-5 rounded-sm ">
              <div className={classnames("flex flex-col")}>
                <label
                  htmlFor="consultant"
                  className={sharedClasses.labelClasses}
                >
                  How much the consultant costs (€)?
                </label>
                <Field
                  type="number"
                  id="consultant"
                  name="consultant"
                  className={sharedClasses.inputClasses}
                  placeholder="How much the consultant costs ?"
                />
                <ErrorMessage
                  name="consultant"
                  component="div"
                  className={sharedClasses.errorClasses}
                />

                <div className="my-2">
                  Total consultant cost (€): {stepTotal}
                </div>

                <div>
                  <Button
                    type="button"
                    color="info"
                    className={classnames(
                      sharedClasses.btnClasses,
                      "text-white"
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Calculate
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={sharedClasses.btnClasses}
                    onClick={() => {
                      previous();
                      navigate("/cost_calculation/callibration-check-cost");
                    }}
                  >
                    Back
                  </Button>
                  {(isSubmitted || hasTotal) && (
                    <Button
                      type="button"
                      color="success"
                      className={sharedClasses.btnClasses}
                      onClick={() => {
                        next();
                        navigate("/cost_calculation/overview");
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ConsultantCost;
